import request from '@/utils/request'

export const createTask: any = (data: any) => {
    return request({
        url: '/task',
        method: 'post',
        data
    })
}

export const searchTask: any = (data: any) => {
    return request({
        url: '/task/search',
        method: 'post',
        data
    })
}

export const updateTask: any = (data: any) => {
    return request({
        url: '/task',
        method: 'put',
        data
    })
}

export const getTask: any = (id: any) => {
    return request({
        url: `/task/${id}`,
        method: 'get',
    })
}

export const deleteTask: any = (id: number) => {
    return request({
        url: `/task/${id}`,
        method: 'delete',
    })
}