
import {Rule} from "ant-design-vue/lib/form";
import {
  defineComponent, onMounted,
  reactive,
  ref,
  toRefs,
  UnwrapRef, watch,
} from "vue";
import {createTask, getTask, updateTask} from "@/api/workflow_task";
import STATUS from "@/components/model/enum";

interface FormState {

  id: string,
  code: string,
  name: string;
  comment: string;
  url: string;
  available: string;
}

export default defineComponent({
  name: "TaskForm",
  emits: ["onCancel"],
  props: ["id", "type"],
  setup(props, context) {
    const data = reactive({
      loading: false,
    });

    watch(props, async (curr) => {

      if (curr.type === 0 || curr.id == 0) return;
      await setFormValue(curr.id);
    });

    let formState: UnwrapRef<FormState> = reactive({
      id: "",
      code: "",
      name: "",
      comment: "",
      url: "",
      available: "",
    });
    const rules: Record<string, Rule[]> = {
      name: [
        {
          required: true,
          trigger: "change",
          validator: (_rule, value) => {
            if (!value) {
              return Promise.reject("请输入流程名称!");
            }
            return Promise.resolve();
          },
        },
      ],
    };

    const handleChange = (value: string) => {
      console.log("---------------")
      console.log(value);
    };
    const refTaskForm = ref()
    const onSubmit = async () => {
      refTaskForm.value.validate().then(() => {
        if (props.type == 'edit') {
          update(formState)
        } else {
          add(formState)
        }
      }).catch(() => {
        context.emit("onCancel", false);
      })

    };

    const add = (option: any) => {
      createTask(option)
          .then(() => {
            context.emit("onCancel", true);
          })
          .catch(() => {
            context.emit("onCancel", false);
          });
    }
    const update = (option: any) => {
      updateTask(option).then(() => {
        context.emit("onCancel", true);
      })
          .catch(() => {
            context.emit("onCancel", false);
          });
    }
    const handleCancel = () => {
      context.emit("onCancel");
    };
    const setFormValue = async (id: any) => {
      const res = await getTask(id);
      console.log(res)
      if (null === res) return;
      for (const key in formState) {
        formState[key as keyof typeof formState] = res[key];
      }
    }
    onMounted(async () => {
      if (props.type == 1) await setFormValue(props.id);
    })

    return {
      ...toRefs(data),
      labelCol: {style: {width: "150px"}},
      wrapperCol: {span: 14},
      formState,
      refTaskForm: refTaskForm,
      rules,
      data,
      onSubmit,
      handleChange,
      handleCancel,
      STATUS,
    };
  },
  components: {},
});
